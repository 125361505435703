import { graphql, useStaticQuery } from "gatsby";

export type Oggetto = {
  nome: string;
  evento: string;
  foto?: { descrizione: string; src: string[] }[];
};
type Props = {
  allDataJson: {
    nodes: { oggetti: Oggetto[] }[];
  };
};

const useOggettiJson = () => {
  const data = useStaticQuery<Props>(graphql`
    query FetchOggettiJson {
      allDataJson {
        nodes {
          oggetti {
            evento
            nome
            foto {
              descrizione
              src
            }
          }
        }
      }
    }
  `);
  console.log(
    "Use oggetti Json: ",
    data.allDataJson.nodes.filter((q) => q.oggetti !== null)
  );
  return data.allDataJson.nodes.filter((q) => q.oggetti !== null)[0];
};

export default useOggettiJson;
