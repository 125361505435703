/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react";
import { PageProps } from "gatsby";
import { IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image";
import Layout from "@lekoarts/gatsby-theme-jodie/src/components/layout";
//import Layout from "./layout"
import GridItem from "./grid-item";
//import GridItem from "@lekoarts/gatsby-theme-jodie/src/components/grid-item";
//import GridItem from "./grid-item"
import {
  itemListWrapperStyles,
  itemStyles,
} from "@lekoarts/gatsby-theme-jodie/src/styles/item-list";
//import { itemListWrapperStyles, itemStyles } from "../styles/item-list";
import locales from "@lekoarts/gatsby-theme-jodie/src/locales";
//import locales from "../locales";
import { visuallyHidden } from "@lekoarts/gatsby-theme-jodie/src/styles/utils";
//import { visuallyHidden } from "../styles/utils";
import modifyGrid from "../utils/modify-grid";
import useQuadriJson, { Quadro } from "../hooks/use-quadri-json";
import useQuadriImages, { ImgQuadri } from "../hooks/use-quadri-images";
import useOggettiJson, { Oggetto } from "../hooks/use-oggetti-json";
import useOggettiImages from "../hooks/use-oggetti-images";
// type DataProps = {
//   projects: {
//     nodes: {
//       slug: string;
//       title: string;
//       cover: {
//         childImageSharp: {
//           gatsbyImageData: IGatsbyImageData;
//         };
//       };
//       __typename: "MdxProject";
//     }[];
//   };
//   pages: {
//     nodes: {
//       slug: string;
//       title: string;
//       cover: {
//         childImageSharp: {
//           gatsbyImageData: IGatsbyImageData;
//         };
//       };
//       __typename: "MdxPage";
//     }[];
//   };
// };

type DataProps = {
  projects: {
    nodes: {
      shortTitle: string;
      slug: string;
      cover: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
    }[];
  };
};

const Homepage: React.FC<PageProps<DataProps>> = ({ data: { projects } }) => {
  const { quadri } = useQuadriJson();
  const { oggetti } = useOggettiJson();
  const { nodes: imgQuadri } = useQuadriImages();
  const { nodes: imgOggetti } = useOggettiImages();

  const rawItems = [...projects.nodes];
  const items = modifyGrid(rawItems);
  const itemsCount = items.length;
  let divisor = 9;

  for (let i = 0; i < itemsCount; i++) {
    const quotient = itemsCount % divisor;
    const quotientAlt = (itemsCount - 1) % divisor;

    if (quotient === 0 || quotientAlt === 0) {
      break;
    }

    divisor -= 1;
  }

  return (
    <Layout>
      <div
        sx={{
          display: `grid`,
          gridTemplateColumns: [`1fr`, `1fr 1fr`],
          gridAutoRows: `50vw`,
        }}
      >
        {items.length > 0 ? (
          items.map((item, index) => (
            <GridItem
              to={item.slug}
              className="item"
              key={item.title}
              data-testid={item.title}
              data={
                item.slug.includes("oggettistica")
                  ? oggetti
                  : quadri.filter(
                      (q) => q.categoria === item.slug.split("/")[1]
                    )
              }
              immagini={
                item.slug.includes("oggettistica")
                  ? imgOggetti
                  : imgQuadri.filter((img) =>
                      img.relativeDirectory.includes(item.slug.split("/")[1])
                    )
              }
            >
              <GatsbyImage
                loading={index === 0 ? `eager` : `lazy`}
                image={item.cover.childImageSharp.gatsbyImageData}
                alt=""
              />
              <span>{item.title}</span>
            </GridItem>
          ))
        ) : (
          <div sx={{ padding: 3 }}>
            No projects and pages found at the locations defined for
            "projectsPath" and "pagesPath"
          </div>
        )}
      </div>
      {/* <div
        sx={{
          display: `grid`,
          gridTemplateColumns: [`1fr`, `1fr 1fr`],
          gridAutoRows: `50vw`,
        }}
      >
        {projects.nodes.length > 0 ? (
          projects.nodes.map((project) => (
            <GridItem
              to={project.slug}
              key={project.slug}
              data-testid={project.shortTitle}
            >
              <GatsbyImage
                image={project.cover.childImageSharp.gatsbyImageData}
                alt=""
              />
              <span>{project.shortTitle}</span>
            </GridItem>
          ))
        ) : (
          <div sx={{ padding: 3 }}>
            No projects found at the location defined for "projectsPath"
          </div>
        )}
      </div> */}
    </Layout>
  );
};

export default Homepage;
