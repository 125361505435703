import { graphql, useStaticQuery } from "gatsby";

export type Quadro = {
  categoria: string;
  data: string;
  descrizione?: string;
  dimensioni?: string;
  matricola?: string;
  prop?: string;
  titolo?: string;
  tipo?: string;
  foto?: string;
};
type Props = {
  allDataJson: {
    nodes: { quadri: Quadro[] }[];
  };
};

const useQuadriJson = () => {
  const data = useStaticQuery<Props>(graphql`
    query MyQuery {
      allDataJson {
        nodes {
          quadri {
            categoria
            data
            descrizione
            dimensioni
            foto
            matricola
            prop
            tipo
            titolo
          }
        }
      }
    }
  `);
  console.log(
    "Use quadri Json: ",
    data.allDataJson.nodes.filter((q) => q.quadri !== null)
  );
  return data.allDataJson.nodes.filter((q) => q.quadri !== null)[0];
};

export default useQuadriJson;
