import { graphql, useStaticQuery } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";

// images: {
//     nodes: {
//       name: string
//       childImageSharp: {
//         gatsbyImageData: IGatsbyImageData
//       }
//     }[]
//   }
export type ImgQuadri = {
  name: string;
  relativePath: string;
  relativeDirectory: string;
  childImageSharp: {
    gatsbyImageData: IGatsbyImageData;
  };
};

type Props = {
  allFile: {
    nodes: ImgQuadri[];
  };
};

const useQuadriImages = () => {
  const data = useStaticQuery<Props>(graphql`
    query FetchQuadriImages {
      allFile(filter: { absolutePath: { regex: "/images/quadri/" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
          name
          relativePath
          relativeDirectory
        }
      }
    }
  `);

  return data.allFile;
};

export default useQuadriImages;
